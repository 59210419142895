import React from 'react';
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import {Link, Link as RouterLink, useNavigate} from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const AppNavbar = () => {
    const navigate = useNavigate();

    const handleNavClick = (e, to) => {
        e.preventDefault();
        navigate(to, { replace: true });
    };

    return (
        <Navbar bg="light" expand="lg" className="bg-white py-3 px-3 fixed-top">
            {/*<Container>*/}
                {/* Logo */}
                <Navbar.Brand>
                    <Link to="/" className="navbar-brand">
                        <img src="/logo1.png" alt="Aleh Logo" className="img-fluid"
                             style={{maxWidth: '150px', maxHeight: '50px'}}/>
                    </Link>
                </Navbar.Brand>

                {/* Toggle button for small screens */}
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                {/* Navbar content */}
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <NavDropdown title="Nos Services" id="basic-nav-dropdown"
                                     className=" me-3 btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center">
                            <h6 className="dropdown-header">Pôle habitat</h6>
                            <NavDropdown.Item href="#">Isolation intérieure</NavDropdown.Item>
                            <NavDropdown.Item href="#">Isolation extérieure</NavDropdown.Item>
                            <NavDropdown.Item href="#">Isolation des combles</NavDropdown.Item>
                            <NavDropdown.Item href="#">Menuiserie</NavDropdown.Item>
                            <NavDropdown.Item href="#">Salle de bain PMR</NavDropdown.Item>
                            <NavDropdown.Item href="#">Vmc double flux</NavDropdown.Item>
                            <h6 className="dropdown-header">Pôle énergie</h6>
                            <NavDropdown.Item href="#">Pompes à chaleur</NavDropdown.Item>
                            <NavDropdown.Item href="#">Photovoltaïques</NavDropdown.Item>
                            <NavDropdown.Item href="#">Panneaux solaires</NavDropdown.Item>
                            <NavDropdown.Item href="#">Climatisation</NavDropdown.Item>
                            <NavDropdown.Item href="#">Ballon thermodynamique</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/calculateur"
                                  className="nav-link  me-3 btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center">
                            Devis en ligne
                        </Nav.Link>
                        <Nav.Link
                            as={RouterLink}
                            to="/#references"
                            onClick={(e) => handleNavClick(e, '/#references')}
                            className="nav-link me-3 btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center"
                        >                          Nos références
                        </Nav.Link>
                        <Nav.Link as={Link} to="/shop" className="nav-link  me-3 btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center">
                            Shop en ligne
                        </Nav.Link>
                        <Nav.Link as={Link} to="/subvention" className="nav-link  me-3 btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center">
                            Calculer ma subvention
                        </Nav.Link>
                        <Nav.Link
                            as={RouterLink}
                            to="/#apropos"
                            onClick={(e) => handleNavClick(e, '/#apropos')}
                            className="nav-link me-3 btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center"
                        >
                            Qui sommes-nous ?
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contact" className="nav-link me-3 btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center">
                            <i className="bi bi-telephone"></i>&nbsp;Appelez-nous
                        </Nav.Link>
                        <Nav.Link href="/login" className="nav-link me-3 d-lg-none btn btn-warning btn-navbar myBtn d-flex align-items-center justify-content-center">
                            Mon Compte
                        </Nav.Link>
                        <Nav.Link href="/login" className="nav-link d-none d-lg-block d-flex align-items-center justify-content-center">
                            <i className="bi bi-person-fill"></i>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            {/*</Container>*/}
        </Navbar>
    );
}

export default AppNavbar;