import React, {useEffect, useState} from 'react';
import {Card, Button, Form, Row, Col} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import CardOption from "../components/CardOption";
import stepData from "../data.json";
import axios from "axios";

const Subvention = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [answers, setAnswers] = useState({
        statut_logement: '',
        besoin: '',
        collecte: false,
        type_logement: '',
        date_construction: '',
        postal_code: '',
        revenu_fiscal: '',
        nombre_personnes: '',
        nom: '',
        prenom: '',
        email: '',
        phone: '',
    });

    const [formErrors, setFormErrors] = useState({
        nom: '',
        prenom: '',
        email: '',
        phone: '',
    });

    const revenuFiscalReference = {
        "BLEU": {
            "1": 17009,
            "2": 24875,
            "3": 29917,
            "4": 34948,
            "5": 40002,
            "additional": 5045
        },
        "JAUNE": {
            "1": 21805,
            "2": 31889,
            "3": 38349,
            "4": 44982,
            "5": 51281,
            "additional": 6462
        },
        "VIOLET": {
            "1": 30549,
            "2": 44907,
            "3": 54071,
            "4": 63235,
            "5": 72400,
            "additional": 9165
        },
        "ROSE": {
            "1": 30550,
            "2": 44908,
            "3": 54072,
            "4": 63236,
            "5": 72401,
            "additional": 9165
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    }, []);

    const handleCardClick = (id, value) => {
        setAnswers({...answers, [id]: value});
    };

    const handleSubmit = () => {
        console.log(answers);
    };

    const handlePrevStep = () => {
        if (step !== 1) setStep(step - 1);
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    };

    const isStepValid = () => {
        if (step === 1) {
            return answers.statut_logement !== '' && answers.besoin !== '';
        } else if (step === 2) {
            return answers.type_logement !== '' && answers.date_construction !== '' && answers.postal_code !== '';
        } else if (step === 3) {
            return answers.nom !== '' && answers.prenom !== '' && answers.email !== '' && answers.phone !== '';
        } else if (step === 4) {
            return answers.revenu_fiscal !== '' && answers.nombre_personnes !== '';
        }
        return true;
    };

    const handleNextStep = async () => {
        if (isStepValid()) {
            if (step === 4) {
                await sendEmail();
            } else {
                setStep(step + 1);
            }
            window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
        }
    };

    const sendEmail = async () => {
        // Form validation
        const errors = {};

        if (answers.nom.trim() === '') {
            errors.nom = 'Champ obligatoire';
        }
        if (answers.prenom.trim() === '') {
            errors.prenom = 'Champ obligatoire';
        }
        if (answers.email.trim() === '') {
            errors.email = 'Champ obligatoire';
        } else {
            // Email format validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(answers.email)) {
                errors.email = 'Format de courriel invalide';
            }
        }
        if (answers.phone.trim() === '') {
            errors.phone = 'Champ obligatoire';
        }

        setFormErrors(errors);

        // If there are no errors, proceed to the next step
        if (Object.keys(errors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mail/subvention`, answers);
                console.log('SUCCESS!', response.data);
                setStep(step + 1);
            } catch (error) {
                console.log('FAILED...', error);
                // Handle error
            }
        }
    };

    const determineCategoryAndValue = (revenuFiscal, nombrePersonnes) => {
        const categories = ["BLEU", "JAUNE", "VIOLET", "ROSE"];

        for (let category of categories) {
            if (nombrePersonnes <= 5) {
                if (revenuFiscal <= revenuFiscalReference[category][nombrePersonnes]) {
                    return {category, value: revenuFiscalReference[category][nombrePersonnes]};
                }
            } else {
                const additionalRevenu = (nombrePersonnes - 5) * revenuFiscalReference[category]["additional"];
                if (revenuFiscal <= (revenuFiscalReference[category][5] + additionalRevenu)) {
                    return {category, value: revenuFiscalReference[category][5] + additionalRevenu};
                }
            }
        }

        // If none of the conditions match, return the highest category
        return {
            category: "ROSE",
            value: revenuFiscalReference["ROSE"][5] + (nombrePersonnes - 5) * revenuFiscalReference["ROSE"]["additional"]
        };
    };

    const calculatedValue = determineCategoryAndValue(parseFloat(answers.revenu_fiscal), parseInt(answers.nombre_personnes));

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setAnswers({
            ...answers,
            [name]: fieldValue,
        });
    };

    return (
        <div className="container mt-5">
            <div className="col">
                <h5>Estimer mes aides</h5>
            </div>
            <div className="container mb-5 p-5"
                 style={{
                     backgroundColor: 'white',
                     borderRadius: '10px',
                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                 }}>
                <p>
                    Propriétaires occupants, propriétaires bailleurs ou syndicat de copropriété, vous êtes susceptibles
                    de bénéficier d’aides financières pour améliorer votre logement. Avant de retrouver le détail de
                    chaque aide, vous pouvez réaliser une simulation des aides pour tous vos projets de travaux.
                </p>

                {step === 1 &&
                    <>
                        <h1 className="calcul-title">Votre besoin</h1>
                        <p>Étape 1 sur 5</p>
                        <p><strong>Étape suivante : </strong> Votre logement</p>
                        <p><code>Les champs marqués d'un * sont requis.</code></p>

                        <Form className="mt-5">
                            <Form.Group className="mb-3" controlId="statut_logement">
                                <Form.Label className="subvention-title">Vous êtes: <span
                                    className="text-danger">*</span></Form.Label>
                                <div className="row row-cols-2 row-cols-md-4 g-4">
                                    <CardOption
                                        key={'Propriétaire Occupant'}
                                        id={'statut_logement'}
                                        value={'Propriétaire Occupant'}
                                        imageSrc={'images/subventions/Propriétaire Occupant.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.statut_logement}
                                    />
                                    <CardOption
                                        key={'Propriétaire bailleur'}
                                        id={'statut_logement'}
                                        value={'Propriétaire bailleur'}
                                        imageSrc={'images/subventions/Propriétaire bailleur.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.statut_logement}
                                    />
                                    <CardOption
                                        key={'Syndicat de copropriétaires'}
                                        id={'statut_logement'}
                                        value={'Syndicat de copropriétaires'}
                                        imageSrc={'images/subventions/Syndicat.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.statut_logement}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="besoin">
                                <Form.Label className="subvention-title">Quel est votre besoin ? <span
                                    className="text-danger">*</span></Form.Label>
                                <div className="row row-cols-2 row-cols-md-4 g-4">
                                    <CardOption
                                        key={'Rénovation énergétique'}
                                        id={'besoin'}
                                        value={'Rénovation énergétique'}
                                        imageSrc={'images/subventions/renovation.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.besoin}
                                    />
                                    <CardOption
                                        key={'Autonomie de la personne'}
                                        id={'besoin'}
                                        value={'Autonomie de la personne'}
                                        imageSrc={'images/subventions/autonomie.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.besoin}
                                    />
                                    <CardOption
                                        key={'Sécurité / Salubrité'}
                                        id={'besoin'}
                                        value={'Sécurité / Salubrité'}
                                        imageSrc={'images/subventions/securite.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.besoin}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="collecte">
                                <Form.Check
                                    type="checkbox"
                                    label="J'accepte la collecte de mes données à des fins d'amélioration du service."
                                    checked={answers.collecte}
                                    onChange={(e) => handleCardClick('collecte', e.target.checked)}
                                />
                            </Form.Group>
                        </Form>
                    </>
                }

                {step === 2 &&
                    <>
                        <h1 className="calcul-title">Votre logement</h1>
                        <p>Étape 2 sur 5</p>
                        <p><strong>Étape suivante : </strong> Votre situation</p>
                        <p><code>Les champs marqués d'un * sont requis.</code></p>

                        <Form className="mt-5">
                            <Form.Group className="mb-3" controlId="type_logement">
                                <Form.Label className="subvention-title">Quel est le type du logement à rénover ? <span
                                    className="text-danger">*</span></Form.Label>
                                <div className="row row-cols-2 row-cols-md-4 g-4">
                                    <CardOption
                                        key={'Maison'}
                                        id={'type_logement'}
                                        value={'Maison'}
                                        imageSrc={'images/subventions/maison.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.type_logement}
                                    />
                                    <CardOption
                                        key={'Appartement'}
                                        id={'type_logement'}
                                        value={'Appartement'}
                                        imageSrc={'images/subventions/appartement.jpeg'}
                                        onClickId={handleCardClick}
                                        selectedAnswer={answers.type_logement}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="dateConstruction">
                                <Form.Label className="subvention-title">De quand date la construction du logement à
                                    rénover ?<span
                                        className="text-danger">*</span></Form.Label>
                                <Form.Group className="mb-3" controlId="date_construction">
                                    <div className="d-flex flex-column">
                                        <Form.Check
                                            type="radio"
                                            label="Moins de 2 ans"
                                            name="date_construction"
                                            id="Moins de 2 ans"
                                            checked={answers.date_construction === "Moins de 2 ans"}
                                            onChange={(e) => handleCardClick('date_construction', 'Moins de 2 ans')}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Entre 2 et 15 ans"
                                            name="date_construction"
                                            id="Entre 2 et 15 ans"
                                            checked={answers.date_construction === "Entre 2 et 15 ans"}
                                            onChange={(e) => handleCardClick('date_construction', 'Entre 2 et 15 ans')}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Plus de 15 ans"
                                            name="date_construction"
                                            id="Plus de 15 ans"
                                            checked={answers.date_construction === "Plus de 15 ans"}
                                            onChange={(e) => handleCardClick('date_construction', 'Plus de 15 ans')}
                                        />
                                    </div>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="postalCode">
                                <Form.Label className="subvention-title">Code postal du logement à rénover :<span
                                    className="text-danger">*</span></Form.Label>
                                <Form.Group className="mb-3" controlId="postal_code">
                                    <div className="d-flex flex-column">
                                        <Form.Control
                                            type="text"
                                            placeholder="Saisissez une ville ou un code postal"
                                            value={answers.postal_code}
                                            onChange={(e) => handleCardClick('postal_code', e.target.value)}
                                        />
                                    </div>
                                </Form.Group>
                            </Form.Group>
                        </Form>
                    </>
                }
                {step === 3 &&
                    <>
                        <h1 className="calcul-title">Vos coordonnées</h1>
                        <p>Étape 3 sur 5</p>
                        <p><strong>Étape suivante : </strong>Vos aides estimées</p>
                        <p><code>Les champs marqués d'un * sont requis.</code></p>

                        <label htmlFor="field10" className="form-label ask mb-3">Vos données de contact</label>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Nom"
                                    aria-label="Nom"
                                    name="nom"
                                    id="lastname"
                                    value={answers.nom}
                                    onChange={handleInputChange}
                                />
                                <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.lastname}</div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Prénom"
                                    aria-label="Prénom"
                                    name="prenom"
                                    id="firstname"
                                    value={answers.prenom}
                                    onChange={handleInputChange}
                                />
                                <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.firstname}</div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <input
                                    className="form-control"
                                    type="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    name="email"
                                    id="email"
                                    value={answers.email}
                                    onChange={handleInputChange}
                                />
                                <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.email}</div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <input
                                    className="form-control"
                                    type="tel"
                                    placeholder="Téléphone"
                                    aria-label="Téléphone"
                                    name="phone"
                                    id="phone"
                                    value={answers.phone}
                                    onChange={handleInputChange}
                                />
                                <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.phone}</div>
                            </div>
                        </div>
                    </>
                }
                {step === 4 &&
                    <>
                        <h1 className="calcul-title">Votre situation</h1>
                        <p>Étape 4 sur 5</p>
                        <p><strong>Étape suivante : </strong>Vos coordonnées</p>
                        <p><code>Les champs marqués d'un * sont requis.</code></p>

                        <Form className="mt-5">
                            <p className="subvention-title">Quelle est votre situation financière ? <span
                                className="text-danger">*</span></p>
                            <p>Ces informations vous sont demandées pour définir votre éligibilité aux aides France
                                Rénov'.</p>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="revenuFiscal">
                                        <Form.Label className="subvention-title">Revenu fiscal de référence du foyer
                                            :<span
                                                className="text-danger">*</span></Form.Label>
                                        <Form.Group className="mb-3" controlId="revenu_fiscal">
                                            <div className="d-flex flex-column">
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Saisissez votre revenu fiscal de référence du foyer"
                                                    value={answers.revenu_fiscal}
                                                    min={0}
                                                    onChange={(e) => handleCardClick('revenu_fiscal', e.target.value)}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="nombrePersonnes">
                                        <Form.Label className="subvention-title">Nombre de personnes composant le foyer
                                            :<span
                                                className="text-danger">*</span></Form.Label>
                                        <Form.Group className="mb-3" controlId="nombre_personnes">
                                            <div className="d-flex flex-column">
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Saisissez le nombre de personnes composant le foyer"
                                                    value={answers.nombre_personnes}
                                                    min={0}
                                                    onChange={(e) => handleCardClick('nombre_personnes', e.target.value)}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Form>
                    </>
                }
                {step === 5 &&
                    <>
                        <h1 className="calcul-title">Vos aides estimées</h1>
                        <p>Étape 5 sur 5</p>
                        <div className="result-card p-4 mb-4">
                            <h5 className="result-title">Catégorie de revenu fiscal :</h5>
                            <p className="result-value">{calculatedValue.category}</p>
                        </div>
                        <div className="result-card p-4 mb-4">
                            <h5 className="result-title">Vous êtes éligible à une aide pouvant aller jusqu'à :</h5>
                            <p className="result-value">{calculatedValue.value.toLocaleString()} €</p>
                        </div>
                    </>
                }
                {/* Next and Previous buttons */}
                <div className="d-flex justify-content-between mt-4">
                    <Button variant="outline-warning" onClick={handlePrevStep}
                            disabled={step === 1}>
                        <i className="bi bi-chevron-left"></i> Précédent
                    </Button>
                    {step !== 5 &&
                        <Button variant="warning" onClick={handleNextStep}
                                disabled={!isStepValid()}>
                            Continuer
                            <i className="bi bi-chevron-right"></i>
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
}

export default Subvention;
