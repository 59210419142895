import React, {useState} from 'react';
import {Card, Button, Form, Col, Row} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import CalculSteps from "../components/CalculSteps";

const CalculChauffage = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});
    const [answers, setAnswers] = useState({
        studyType: 'chauffage',
        dossierRef: '',
        clientName: '',
        clientAddress: '',
        clientCity: '',
        clientPostalCode: '',
        clientPhone: '',
        clientEmail: '',
        installCompany: '',
        installName: '',
        installAddress: '',
        installCity: '',
        installPostalCode: '',
        installPhone: '',
        installEmail: '',
        department: ''
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [name]: value,
        }));
    };

    const handleStep1Submit = (e) => {
        e.preventDefault();

        const newErrors = {};
        const requiredFields = [
            'dossierRef', 'clientName', 'installCompany'
        ];

        requiredFields.forEach(field => {
            if (!answers[field]) {
                newErrors[field] = 'Valeur requise';
            }
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // All required fields are filled, proceed to the next step
            setStep(2);
            window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
        } else {
            // There are errors, do not proceed
            window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
            console.log("There are errors. Do not proceed.");
        }
    };

    if (step === 1) {
        return (
            <>
                <CalculSteps activeStep={step}/>
                <div className="container mt-5">
                    <div className="container mb-5 p-5"
                         style={{
                             backgroundColor: 'white',
                             borderRadius: '10px',
                             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                         }}>
                        <Form>
                            <h1 className="calcul-title">Dossier</h1>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="dossierRef">
                                        <Form.Control
                                            type="text"
                                            placeholder="Saisir la référence du dossier *"
                                            name="dossierRef"
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.dossierRef}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.dossierRef}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <p>Les champs marqués par une * sont obligatoires</p>

                            <h1 className="calcul-title">Client</h1>
                            <p>Veuillez saisir les coordonnées du client *</p>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="clientName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Prénom, Nom *"
                                            name="clientName"
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.clientName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.clientName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="clientAddress">
                                        <Form.Control
                                            type="text"
                                            placeholder="Adresse"
                                            name="clientAddress"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="clientCity">
                                        <Form.Control
                                            type="text"
                                            placeholder="Ville"
                                            name="clientCity"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="clientPostalCode">
                                        <Form.Control
                                            type="text"
                                            placeholder="Code Postal"
                                            name="clientPostalCode"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="clientPhone">
                                        <Form.Control
                                            type="tel"
                                            placeholder="Téléphone"
                                            name="clientPhone"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="clientEmail">
                                        <Form.Control
                                            type="email"
                                            placeholder="Courriel"
                                            name="clientEmail"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <h1 className="calcul-title">Installateur</h1>
                            <p>Veuillez saisir les coordonnées de la société qui réalisera l'installation *</p>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="installCompany">
                                        <Form.Control
                                            type="text"
                                            placeholder="Société *"
                                            name="installCompany"
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.installCompany}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.installCompany}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="installName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Prénom, Nom"
                                            name="installName"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="installAddress">
                                        <Form.Control
                                            type="text"
                                            placeholder="Adresse"
                                            name="installAddress"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="installCity">
                                        <Form.Control
                                            type="text"
                                            placeholder="Ville"
                                            name="installCity"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="installPostalCode">
                                        <Form.Control
                                            type="text"
                                            placeholder="Code Postal"
                                            name="installPostalCode"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="installPhone">
                                        <Form.Control
                                            type="tel"
                                            placeholder="Téléphone"
                                            name="installPhone"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="installEmail">
                                        <Form.Control
                                            type="email"
                                            placeholder="Courriel"
                                            name="installEmail"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

                <div className="container my-5">
                    <div className="container p-3"
                         style={{
                             backgroundColor: 'white',
                             borderRadius: '10px',
                             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                         }}>
                        <div className="d-flex justify-content-between">
                            <Link to="/calcul_piece_par_piece">
                                <button className="btn btn-outline-warning">Retour à l'accueil</button>
                            </Link>
                            <button className="btn btn-warning" onClick={handleStep1Submit}>Continuer vers
                                l'installation
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (step === 2) {
        return (
            <>
                <CalculSteps activeStep={step}/>
                <div className="container mt-5">
                    <div className="container mb-5 p-5"
                         style={{
                             backgroundColor: 'white',
                             borderRadius: '10px',
                             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                         }}>
                        <Form>
                            <h1 className="calcul-title">Méthode</h1>

                            <h1 className="calcul-title">Situation de l'installation</h1>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="department">
                                        <Form.Label>Département</Form.Label>
                                        <Form.Select
                                            name="department"
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.department}
                                        >
                                            <option value="">Sélectionnez un département *</option>
                                            {Array.from({length: 95}, (_, i) => (
                                                <option key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                                    {String(i + 1).padStart(2, '0')}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.department}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="">
                                        <Form.Label>Altitude</Form.Label>
                                        <Form.Select
                                            name="altitude"
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Altitude *</option>
                                            <option value="0-200">0 à 200 m</option>
                                            <option value="201-400">201 à 400 m</option>
                                            <option value="401-600">401 à 600 m</option>
                                            <option value="601-800">601 à 800 m</option>
                                            <option value="801-1000">801 à 1000 m</option>
                                            <option value="1001-1200">1001 à 1200 m</option>
                                            <option value="1201-1400">1201 à 1400 m</option>
                                            <option value="1401-1600">1401 à 1600 m</option>
                                            <option value="1601-1800">1601 à 1800 m</option>
                                            <option value="1801-2000">1801 à 2000 m</option>
                                            <option value="2001-2200">2001 à 2200 m</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.altitude}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <p>Consigne de chauffage fixe à 19,5°C</p>
                            <h1 className="calcul-title">Description du bâtiment</h1>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="">
                                        <Form.Label>Année de construction</Form.Label>
                                        <Form.Select
                                            name="constructionYear"
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Année de construction *</option>
                                            <option value="-1974">Avant 1974</option>
                                            <option value="1974-1981">de 1974 à 1981</option>
                                            <option value="1982-1988">de 1982 à 1988</option>
                                            <option value="1989-1999">de 1989 à 1999</option>
                                            <option value="2000-2004">de 2000 à 2004</option>
                                            <option value="2005-2012">de 2005 à 2012</option>
                                            <option value="2013-2017">de 2013 à 2017</option>
                                            <option value="2018-2024">de 2018 à 2024</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.constructionYear}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h1 className="calcul-title">Améliorations après construction</h1>
                        </Form>
                    </div>
                </div>

                <div className="container my-5">
                    <div className="container p-3"
                         style={{
                             backgroundColor: 'white',
                             borderRadius: '10px',
                             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                         }}>
                        <div className="d-flex justify-content-between">
                            <Link to="/calcul_piece_par_piece">
                                <button className="btn btn-outline-warning">Retour à l'accueil</button>
                            </Link>
                            <button className="btn btn-warning" onClick={handleStep1Submit}>Continuer vers l'étude
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CalculChauffage;
