import React, {useState} from 'react';
import {Card, Button, Form} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import CardOption from "../components/CardOption";
import { useNavigate } from 'react-router-dom';

const CalculPieceParPiece = () => {
    const navigate = useNavigate();
    const {studyType} = useParams();
    const [answers, setAnswers] = useState(
        {studyType: ''}
    );

    const handleCardClick = (id) => {
        setAnswers({...answers, studyType: id})
    };

    const handleStudyType = () => {
        if (answers.studyType === 'chauffage') {
            navigate('/calcul_piece_par_piece/chauffage');
        } else if (answers.studyType === 'refroidissement') {
            navigate('/calcul_piece_par_piece/refroidissement');
        }
    };

    if (studyType === "studyType") {
        return (
            <div className="container mt-5">
                <div className="col">
                    <h5>Sélectionner le type de l'étude</h5>
                </div>
                <div className="container mb-5 p-5 text-center"
                     style={{
                         backgroundColor: 'white',
                         borderRadius: '10px',
                         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                     }}>
                    <Form>
                        <div className="row row-cols-2 row-cols-md-4 g-4 justify-content-center">
                            <CardOption
                                key={'chauffage'}
                                id={'chauffage'}
                                value={'Chauffage'}
                                imageSrc={'https://outil-calcul-piece-par-piece.vaillant.fr/_nuxt/img/heatpump.abd9da0.svg'}
                                onClick={handleCardClick}
                                selectedAnswer={answers.studyType}
                            />
                            <CardOption
                                key={'rafraichissement'}
                                id={'rafraichissement'}
                                value={'Rafraichissement'}
                                imageSrc={'https://outil-calcul-piece-par-piece.vaillant.fr/_nuxt/img/aircon.c732afb.svg'}
                                onClick={handleCardClick}
                                selectedAnswer={answers.studyType}
                            />
                        </div>
                        <Button className="btn btn-warning mt-3" onClick={handleStudyType}>Créer l'étude</Button>
                    </Form>
                </div>
            </div>
        );
    }

    if (studyType === undefined) {
        return (
            <div className="container my-5 p-5 d-flex justify-content-center align-items-center text-center"
                 style={{
                     backgroundColor: 'white',
                     borderRadius: '10px',
                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                 }}>
                <div>
                    <h4>
                        Estimez rapidement les déperditions de chaleur pièce par pièce et déterminez la température de
                        départ
                        des radiateurs.
                    </h4>
                    <Link to="/dashboard">
                        <Button className="btn btn-warning mt-3">Accédez à l'application</Button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default CalculPieceParPiece;
