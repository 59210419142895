import React, {useEffect} from 'react';
import PartnersCarousel from '../components/PartnersCarousel';
import Activities from "../components/Activities";
import HeroBanner from "../components/HeroBanner";
import Products from "../components/Products";
import Labels from "../components/Labels";
import Map from "../components/Map";
import Advisors from "../components/Advisors";
import PopupModal from "../components/PopupLivreBlanc";

const LandingPage = () => {
    // useEffect(() => {
    //     window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    // }, []);

    return (
        <>
            <HeroBanner/>
            <Map/>
            {/*<Advisors/>*/}
            <Activities/>
            <Products/>
            <Labels/>
            <PartnersCarousel/>
            <PopupModal />
        </>
    );
}

export default LandingPage;
