import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ContactComponent = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: ''
    });

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mail/contact`, formData);
            setModalMessage('On a reçu vos informations avec succès !');
            setShowModal(true);
            setFormData({
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error('An error occurred while sending the email:', error);
            setModalMessage("Une erreur s'est produite lors de l'enregistrement. Veuillez réessayer plus tard.");
            setShowModal(true);
        }
    }

    return (
        <div className="container my-5">
            <h1 className="text-center mt-3 mb-4">Contactez-nous</h1>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">Prénom</label>
                            <input type="text" className="form-control" id="firstName" value={formData.firstName} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastName" className="form-label">Nom</label>
                            <input type="text" className="form-control" id="lastName" value={formData.lastName} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Téléphone</label>
                            <input type="tel" className="form-control" id="phone" value={formData.phone} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea className="form-control" id="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
                        </div>
                        <button type="submit" className="btn btn-warning">Envoyer</button>
                    </form>
                </div>
            </div>
            {/* Bootstrap Modal */}
            <div className={`modal fade ${showModal ? 'show d-block' : ''}`} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Notification</h5>
                        </div>
                        <div className="modal-body">
                            <p>{modalMessage}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" data-dismiss="modal" onClick={() => setShowModal(false)}>Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Bootstrap Modal */}
        </div>
    );
}

export default ContactComponent;
