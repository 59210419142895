import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Dashboard = () => {
    return (
        <>
            <div className="text-center mt-5">
                <Link to="/calcul_piece_par_piece/studyType">
                    <Button className="btn btn-warning mt-3">Créez une nouvelle étude</Button>
                </Link>
            </div>


            <div>
                <div  className="container mt-5" >
                    <div className="row">
                        <div className="col">
                            <h5>Mes études</h5>
                        </div>
                        <div className="col">
                            <p className="text-end">0/5</p>
                        </div>
                    </div>
                    <div className="container mb-5 p-5 text-center"
                         style={{
                             backgroundColor: 'white',
                             borderRadius: '10px',
                             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                         }}>
                        <p>
                            Vous n'avez pas d'étude en mémoire sur ce navigateur.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
