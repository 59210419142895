import React from 'react';

const Steps = ({activeStep}) => {
    return (
        <div className="container mt-5">
            <div className="container p-3 text-center"
                 style={{
                     backgroundColor: 'white',
                     borderRadius: '10px',
                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                 }}>
                <div className="d-flex justify-content-around">
                    <Step number={1} label="Projet" isActive={activeStep === 1}/>
                    <Step number={2} label="Installation" isActive={activeStep === 2}/>
                    <Step number={3} label="Etude" isActive={activeStep === 3}/>
                    <Step number={4} label="Publication" isActive={activeStep === 4}/>
                </div>
            </div>
        </div>
    );
}

const Step = ({number, label, isActive}) => {
    return (
        <div className="calcul-step">
            <p className="p-0 m-0">
                <span className={`calcul-step-number ${isActive ? 'active' : ''} me-2 mb-0`}>{number}</span>
                <span className={`calcul-step-label ${isActive ? 'active' : ''}`}>{label}</span>
            </p>
        </div>
    );
}

export default Steps;
