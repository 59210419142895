import React, {useState} from 'react';
import {Card, Button, Form} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const CalculRefroidissement = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [answers, setAnswers] = useState(
        {studyType: ''}
    );

    const handleCardClick = (id) => {
        setAnswers({...answers, studyType: id})
    };

    if (step === 1) {
        return (
            <div className="container mt-5">
                <div className="col">
                    <h5>Sélectionner le type de l'étude</h5>
                </div>
                <div className="container mb-5 p-5 text-center"
                     style={{
                         backgroundColor: 'white',
                         borderRadius: '10px',
                         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                     }}>
                    <Form>

                    </Form>
                </div>
            </div>
        );
    }
}

export default CalculRefroidissement;
