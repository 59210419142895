import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import Chatbot from "./chatbot";

const Layout = ({children}) => {
    return (
        <>
            <Navbar/>
            <div className="flex-wrapper">
                <main style={{paddingTop: '100px'}}>
                    {children}
                </main>
                <Chatbot/>
                <Footer/>
            </div>
        </>
    );
}

export default Layout;