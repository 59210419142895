import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

const Labels = () => {
    const location = useLocation();

    useEffect(() => {
        const scrollToHash = () => {
            const hash = location.hash;
            if (hash && hash.startsWith('#references')) {
                const id = hash.replace('#', '');
                setTimeout(() => {
                    scroller.scrollTo(id, {
                        duration: 500,
                        delay: 0,
                        smooth: 'easeInOutQuart',
                        offset: -90 // Adjust offset for fixed header, etc.
                    });
                }, 100); // Adjust delay to ensure content is rendered
            }
        };

        // Call scrollToHash once to handle the initial load
        scrollToHash();

        // Listen for hash changes
        window.addEventListener('hashchange', scrollToHash);

        return () => {
            window.removeEventListener('hashchange', scrollToHash);
        };
    }, [location]);

    const labels = [
        { src: "/images/landing/partners/CEE.png", alt: "CEE" },
        { src: "/images/landing/partners/Maprimerenov.png", alt: "MaPrimRenov" },
        { src: "/images/landing/partners/qualibat-rge.png", alt: "Qualibat" },
        { src: "/images/landing/partners/Qualisol.png", alt: "Qualisol" },
        { src: "/images/landing/partners/QualiPV.png", alt: "QualiPV" },
        { src: "/images/landing/partners/QualiPAC.png", alt: "QualiPAC" },
        { src: "/images/landing/partners/Qualibois.png", alt: "Qualibois" },
        { src: "/images/landing/partners/QualiForage.png", alt: "QualiForage" },
        { src: "/images/landing/partners/Chauffage.png", alt: "Chauffage" },
        { src: "/images/landing/partners/qualifelec.png", alt: "qualifelec" },
        { src: "/images/landing/partners/anah.png", alt: "anah" },
        { src: "/images/landing/partners/habiter-mieux.png", alt: "habiter-mieux" },
        { src: "/images/landing/partners/eco-pret.png", alt: "eco-pret" },
    ];

    return (
        <div className="container my-5" id="references">
            <h1 className="text-center mt-3 mb-4">Nos labels et Certifications</h1>
            <div className="container overflow-hidden">
                <div className="row gy-5 gy-md-6 justify-content-center">
                    {labels.map((label, index) => (
                        <div key={index} className="col-6 col-md-3 align-self-center text-center">
                            <img
                                src={label.src}
                                className="img-fluid"
                                alt={label.alt}
                                style={{ maxHeight: '95px', maxWidth: '100%', margin: 'auto' }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Labels;
