import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyA6E-iL4NHGRfPrmVZPf_7vcrSZ72lvxfI",
    authDomain: "aleh-15111.firebaseapp.com",
    projectId: "aleh-15111",
    storageBucket: "aleh-15111.appspot.com",
    messagingSenderId: "858918340893",
    appId: "1:858918340893:web:dcdd8d3de2cfe1e5dd79f4"
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
