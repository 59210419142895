import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-image text-light position-relative" style={{ marginTop: 'auto' }}>
            <div className="bg-overlay position-absolute top-0 start-0 end-0 bottom-0"></div>
            <Container className="py-5 position-relative" style={{ zIndex: 1 }}>
                <h2 className="text-center mb-4 widget-title" style={{ color: '#ffd800' }}>Sauvons notre avenir et notre planète ensemble</h2>
                <Row>
                    <Col lg={3} className="text-center text-lg-start pt-5 pt-lg-0 coordonnees">
                        <p><strong>Aleh.fr</strong></p>
                        <div className="mt-3">
                            <a href='mailto:contact@aleh.fr' target="_blank"
                               className="text-light text-decoration-none">
                                <i className="bi bi-envelope"></i> contact@aleh.fr
                            </a>
                        </div>
                        <div className="mt-3">
                            <a href='tel:0987721953' target="_blank"
                               className="text-light text-decoration-none">
                                <i className="bi bi-telephone"></i> 09 87 72 19 53
                            </a>
                        </div>
                    </Col>
                    
                    <Col lg={3} className="text-lg-start pt-5 pt-lg-0 mx-auto" style={{ maxWidth: '400px' }}>
                        <p><strong>Nos Agences</strong></p>
                        <div className="mt-3">
                            <p className="my-1">Agence Grand-Est</p>
                            <p className="my-1">Agence Rhône-Loire</p>
                            <p className="my-1">Agence Drôme-Ardèche</p>
                            <p className="my-1">Agence Méditerranée</p>
                        </div>
                    </Col>
                    <Col lg={3} className="text-center text-lg-start pt-5 pt-lg-0 coordonnees">
                        <p><strong>Suivez-nous</strong></p>
                        <div className="mt-3">
                            <a href="https://www.facebook.com/profile.php?id=61556437809109" target="_blank" className="me-3 fs-4 text-light text-decoration-none">
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/alehfr2024/" target="_blank" className="me-3 fs-4 text-light text-decoration-none">
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/aleh-fr-2140072b6/" target="_blank" className="me-3 fs-4 text-light text-decoration-none">
                                <i className="bi bi-linkedin"></i>
                            </a>
                            <a href="https://twitter.com/fr_aleh" target="_blank" className="me-3 fs-4 text-light text-decoration-none">
                                <i className="bi bi-twitter"></i>
                            </a>
                            <a href="https://www.pinterest.fr/alehfr0415/" target="_blank" className="me-3 fs-4 text-light text-decoration-none">
                                <i className="bi bi-pinterest"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCJa47TQZx3TNSQjrdYMf29w" target="_blank" className="me-3 fs-4 text-light text-decoration-none">
                                <i className="bi bi-youtube"></i>
                            </a>
                        </div>
                    </Col>
                </Row>
                {/*  <hr />
                <Row className="mt-3">
                    <Col>
                        <p className="text-center">&copy;2024 Solaire</p>
                    </Col>
                </Row>*/}
            </Container>
        </footer>
    );
}

export default Footer;
