// src/LandbotChat.js
import React, {useEffect, useState} from 'react';

const LandbotChat = () => {
    const useWindowWidth = () => {
        const [width, setWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWidth(window.innerWidth);

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return width;
    };

    const width = useWindowWidth();

    useEffect(() => {
        const initLandbot = () => {
            if (!window.myLandbot) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.addEventListener('load', () => {
                    window.myLandbot = new window.Landbot.Livechat({
                        configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-2510129-D2G9FAJJUCEKYVFJ/index.json',
                    });
                });
                script.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
                const firstScript = document.getElementsByTagName('script')[0];
                firstScript.parentNode.insertBefore(script, firstScript);
            }
        };

        // if (width >= 576) {
            window.addEventListener('mouseover', initLandbot, { once: true });
            window.addEventListener('touchstart', initLandbot, { once: true });
        // }

        return () => {
            window.removeEventListener('mouseover', initLandbot);
            window.removeEventListener('touchstart', initLandbot);
        };
    }, [width]);

    // if (width < 576) {
    //     return null; // Hide on small screens
    // }

    return null;
};

export default LandbotChat;
