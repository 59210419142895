import React from 'react';

const CardOption = ({ id, value, imageSrc, onClick, selectedAnswer, onClickId }) => {
    let isSelected = selectedAnswer === id;
    if (selectedAnswer === true) isSelected = true;
    if (onClickId)
        isSelected = selectedAnswer === value;

    const handleMouseEnter = () => {
        document.getElementById(id).classList.add('hoveredCard');
    };

    const handleMouseLeave = () => {
        document.getElementById(id).classList.remove('hoveredCard');
    };

    return (
        <div className="col">
            <label htmlFor={id} className="w-100 h-100">
                <input type="radio" name="product" value={value} id={id} style={{ display: 'none' }} />
                <div
                    id={id}
                    className={`card h-100 answer hoveredCard  ${isSelected ? 'selectedCard' : ''}`}
                    onClick={() => {
                        onClickId ?
                            onClickId(id, value) :
                            onClick(id)
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    { imageSrc &&
                        <img
                            src={imageSrc}
                            className="card-img-top"
                            alt=""
                            style={{ /*objectFit: 'cover',*/ height: '200px' }} // Maintain aspect ratio without shrinking
                        />
                    }
                    <div className="card-body text-center">
                        <h6 className="card-title">{value}</h6>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default CardOption;
