import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Calculator from "./views/Calculator";
import LandingPage from "./views/LandingPage";
import Contact from "./views/Contact";
import Shop from "./views/Shop";
import ShopDetails from "./views/ShopDetails";
import CalculPieceParPiece from "./views/CalculPieceParPiece";
import CalculRefroidissement from "./views/CalculRefroidissement";
import CalculChauffage from "./views/CalculChauffage";
import Dashboard from "./views/Dashboard";
import Subvention from "./views/Subvention";
import LivreBlancForm from "./views/LivreBlancForm";
import Login from "./views/auth/Login";
import Signup from "./views/auth/Signup";
import CartProvider from './components/CartContext';
import CartComponent from './components/Cart';

function App() {
    return (
        <BrowserRouter>
            <CartProvider>
                <Layout>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/calculateur" element={<Calculator />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/shop" element={<Shop />} />
                        <Route path="/shop/:productId" element={<ShopDetails />} />
                        <Route path="/cart" element={<CartComponent />} />
                        <Route path="/calcul_piece_par_piece" element={<CalculPieceParPiece />} />
                        <Route path="/calcul_piece_par_piece/chauffage" element={<CalculChauffage />} />
                        <Route path="/calcul_piece_par_piece/refroidissement" element={<CalculRefroidissement />} />
                        <Route path="/calcul_piece_par_piece/:studyType" element={<CalculPieceParPiece />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/subvention" element={<Subvention />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/livreBlanc" element={<LivreBlancForm />} />
                    </Routes>
                </Layout>
            </CartProvider>
        </BrowserRouter>
    );
}

export default App;
