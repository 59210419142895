import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import CardOption from "../components/CardOption";
import stepData from '../data.json';
import ContactForm from "../components/ContactForm";
import EstimationForm from "../components/EstimationForm";
import axios from "axios";
import {json} from "react-router-dom";

const Calculator = () => {
    const stepLabels = ['Produit', 'Bâtiment', 'Consommation', 'Informations', 'Estimation'];
    const [step, setStep] = useState(1); // State to track the current step
    const [subStep, setSubStep] = useState(1); // State to track the current sub-step in step 2
    const [loading, setLoading] = useState(false); // State to track loading status
    // State variables for form field values and errors
    const [formData, setFormData] = useState({
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        rgpd: false,
    });

    const [formErrors, setFormErrors] = useState({
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        rgpd: '',
    });

    // State variable to store selected answers for each step and substep
    const [selectedAnswers, setSelectedAnswers] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    }, []);

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: fieldValue,
        });
    };

    const translateSelectedAnswers = () => {
        const translatedAnswers = {};

        Object.keys(selectedAnswers).forEach(stepKey => {
            const step = selectedAnswers[stepKey];
            translatedAnswers[stepKey] = {};

            Object.keys(step).forEach(subStepKey => {
                const answerIds = step[subStepKey];

                if (Array.isArray(answerIds)) {
                    // Handle array of answers for multiple selections
                    translatedAnswers[stepKey][subStepKey] = answerIds.map(answerId => {
                        const option = stepData[stepKey][subStepKey].options.find(opt => opt.id === answerId);
                        return option ? option.value : answerId;
                    }).join(', ');
                } else {
                    // Handle single selection answers
                    const option = stepData[stepKey][subStepKey].options.find(opt => opt.id === answerIds);
                    translatedAnswers[stepKey][subStepKey] = option ? option.value : answerIds;
                }
            });
        });

        return translatedAnswers;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Form validation
        const errors = {};

        if (formData.lastname.trim() === '') {
            errors.lastname = 'Champ obligatoire';
        }
        if (formData.firstname.trim() === '') {
            errors.firstname = 'Champ obligatoire';
        }
        if (formData.email.trim() === '') {
            errors.email = 'Champ obligatoire';
        } else {
            // Email format validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                errors.email = 'Format de courriel invalide';
            }
        }
        if (formData.phone.trim() === '') {
            errors.phone = 'Champ obligatoire';
        }
        if (!formData.rgpd) {
            errors.rgpd = 'Vous devez accepter nos politiques des données';
        }

        setFormErrors(errors);

        // If there are no errors, proceed to the next step
        if (Object.keys(errors).length === 0) {
            const form = {
                lastname: formData.lastname,
                firstname: formData.firstname,
                email: formData.email,
                phone: formData.phone,
                selectedAnswers: JSON.stringify(translateSelectedAnswers())
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mail/devis`, form);
                console.log('SUCCESS!', response.data);
                handleNextStep();
            } catch (error) {
                console.log('FAILED...', error);
                // Handle error
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const handleNextStep = (nextStep = 0) => {
        switch (nextStep) {
            case 0:
                console.log(selectedAnswers);
                if (subStep === 3.1) {
                    // If substep is 3.1, go back to step 3, substep 1
                    setStep(3);
                    setSubStep(1);
                    return;
                } else if (subStep === 1.2) {
                    // If substep is 1.2, go back to step 2, substep 1
                    setStep(2);
                    setSubStep(1);
                    return;
                } else if (step === 1 && selectedAnswers[1] && selectedAnswers[1][1] && selectedAnswers[1][1].includes('service_joinery')) {
                    // If step is 1 and substep 1.2 is selected, move to substep 1.2
                    setSubStep(1.2);
                    return;
                }
                break;
            default:
                // If nextStep is not 0, set the substep to nextStep
                setSubStep(nextStep);
                return;
        }

        // If none of the above conditions are met, proceed to the next step
        if (step === 2) {
            if (subStep < 3) {
                // If the current step is 2 (Bâtiment), proceed to the next sub-step
                setSubStep(subStep + 1);
            } else {
                // If all sub-steps are completed, move to the next step
                setStep(step + 1);
                setSubStep(1); // Reset sub-step to 1
            }
        } else if (step === 3) {
            if (subStep < 4) {
                // If the current step is 3 (Consommation), check if all sub-steps are completed
                setSubStep(subStep + 1);
            } else {
                // If all sub-steps are completed, move to the next step
                setStep(step + 1);
                setSubStep(1); // Reset sub-step to 1 for step 4
            }
        } else if (step === 4) {
            if (subStep < 2) {
                // If the current step is 4, check if all sub-steps are completed
                setSubStep(subStep + 1);
            } else {
                // If all sub-steps are completed, move to the next step
                setStep(step + 1);
                setSubStep(1);
            }
        } else {
            // For other steps, proceed to the next step
            setStep(step + 1);
        }
    };

    const handlePrevStep = () => {
        switch (subStep) {
            case 3.1:
                // If substep is 3.1, go back to substep 2
                setSubStep(2);
                return;
            case 1.2:
                // If substep is 1.2, go back to substep 1
                setSubStep(1);
                return;
            default:
                break;
        }

        switch (step) {
            case 2:
                if (subStep > 1) {
                    // If the current step is 2 (Bâtiment) and sub-step is not the first one, move to the previous sub-step
                    setSubStep(subStep - 1);
                    return;
                } else {
                    // If the current step is 2 and it's the first sub-step, handle special cases
                    if (selectedAnswers[1] && selectedAnswers[1][1] === 'service_joinery') {
                        setSubStep(1.2);
                    } else {
                        setSubStep(1);
                    }
                    break;
                }
            case 3:
                if (subStep > 1) {
                    // If the current step is 3 (Consommation) and sub-step is not the first one, move to the previous sub-step
                    setSubStep(subStep - 1);
                    return;
                } else {
                    // If the current step is 3 and it's the first sub-step, reset sub-step to the last one for step 2
                    setSubStep(3);
                    break;
                }
            case 4:
                if (subStep > 1) {
                    // If the current step is 4 and sub-step is not the first one, move to the previous sub-step
                    setSubStep(subStep - 1);
                    return;
                } else {
                    // If the current step is 4 and it's the first sub-step, reset sub-step to the last one for step 3
                    setSubStep(4);
                    break;
                }
            case 5:
                // If the current step is 5, reset sub-step to the last one for step 4
                setSubStep(2);
                break;
            default:
                break;
        }

        // Move to the previous step
        setStep(step - 1);
    };

    const handleCardClick = (id) => {
        if (step === 1 && subStep === 1) {
            const updatedAnswers = selectedAnswers[step]?.[subStep]?.includes(id)
                ? selectedAnswers[step][subStep].filter(answerId => answerId !== id)
                : [...(selectedAnswers[step]?.[subStep] || []), id];

            setSelectedAnswers({
                ...selectedAnswers,
                [step]: {
                    ...selectedAnswers[step],
                    [subStep]: updatedAnswers,
                },
            });

        } else {
            setSelectedAnswers({
                ...selectedAnswers,
                [step]: {
                    ...selectedAnswers[step],
                    [subStep]: id,
                },
            });

            if (id === 'roofType_flat') {
                setTimeout(() => handleNextStep(3.1), 1000);
            } else {
                setTimeout(handleNextStep, 1000);
            }
        }
    };

    const isNextButtonDisabled = () => {
        if (step === 1 && subStep === 1) {
            // Check if there is at least one selected answer for the first step and first substep
            return !(step in selectedAnswers) || !(subStep in selectedAnswers[step]) || selectedAnswers[step][subStep].length === 0;
        } else {
            // Check if there is a selected answer for other steps and substeps
            return !(step in selectedAnswers) || !(subStep in selectedAnswers[step]);
        }
    };

    return (
        <div className="py-5 bg-light">
            <Container>
                <h1 className="text-center mb-5">
                    J’estime mes économies et mes subventions en quelques clics
                </h1>

                {/* Stepper */}
                <div className="step-container d-flex justify-content-between mb-4">
                    {[1, 2, 3, 4, 5].map((num) => (
                        <div key={num} className={`step-circle ${step === num ? 'currentStep canClick' : ''}`}
                             data-step={num}>
                            <p><strong>{stepLabels[num - 1] }</strong></p>
                            {num}
                        </div>
                    ))}
                    {/* Progress Bar */}
                    <div className="progress-bar" style={{width: `${((step - 1) * 25) + ((subStep - 1) * 5)}%`}}></div>
                </div>

                {/* Form */}
                <Row>
                    <Col lg={12} className="align-self-center">

                        <div className="mt-4">
                            <Form>
                                {(!(step === 4 && subStep === 2) && step !== 5) &&
                                    <Form.Group controlId="product" className="card my-4 p-4 shadow-sm">
                                        <Form.Label className="ask mb-3">{stepData[step][subStep].label}</Form.Label>
                                        <div className="row row-cols-2 row-cols-md-3 g-4">
                                            {stepData[step][subStep].options.map((option) => (
                                                <CardOption
                                                    key={option.id}
                                                    id={option.id}
                                                    value={option.value}
                                                    imageSrc={option.imageSrc}
                                                    onClick={handleCardClick}
                                                    selectedAnswer={
                                                        step === 1 && subStep === 1
                                                            ? selectedAnswers[step]?.[subStep]?.includes(option.id)
                                                            : selectedAnswers[step] && selectedAnswers[step][subStep] === option.id
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </Form.Group>
                                }
                                {step === 4 && subStep === 2 && (
                                    <ContactForm
                                        formData={formData}
                                        formErrors={formErrors}
                                        handleInputChange={handleInputChange}
                                    />
                                )}
                                {step === 5 && (
                                    <EstimationForm />
                                )}

                                {/* Next and Previous buttons */}
                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="outline-primary" onClick={handlePrevStep} disabled={step === 1 && subStep ===1}><i
                                        className="bi bi-chevron-left"></i> Retour</Button>
                                    {((step !== 4 || subStep !== 2) && step !== 5) &&
                                        <Button variant="primary" onClick={() => handleNextStep()}
                                                disabled={step === 5 || isNextButtonDisabled()}>Continuer <i
                                            className="bi bi-chevron-right"></i></Button>}
                                    {step === 4 && subStep === 2 &&
                                        <button type="submit" id="multi-step-form_submit" className="btn btn-primary"
                                                disabled={loading}
                                                onClick={handleSubmit}>
                                            J'obtiens mes estimations{' '}
                                            <i className="bi bi-chevron-right"></i>
                                        </button>
                                    }
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Calculator;
