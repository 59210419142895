import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";

const PopupModal = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 10000); // 30000 ms = 30 seconds

        return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }, []);

    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body closeButton>
                <p>Téléchargez le livre blanc sur la rénovation globale énergétique</p>
            </Modal.Body>
            <Modal.Footer>
                <Link to="/livreBlanc">
                    <Button variant="warning">
                        Télécharger
                    </Button>
                </Link>
            </Modal.Footer>
        </Modal>
    );
};

export default PopupModal;
