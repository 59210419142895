import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { Alert } from "react-bootstrap";

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear any previous error message

        if (password !== confirmPassword) {
            setErrorMessage('Les mots de passe ne correspondent pas.');
            return;
        }

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            // Handle successful signup, e.g., navigate to a different page
            navigate('/profil'); // Redirect to a different page upon successful signup
        } catch (error) {
            let message = 'Erreur lors de l\'inscription : ';
            switch (error.code) {
                case 'auth/email-already-in-use':
                    message += 'Email déjà utilisé.';
                    break;
                case 'auth/invalid-email':
                    message += 'Email invalide.';
                    break;
                case 'auth/weak-password':
                    message += 'Mot de passe trop faible.';
                    break;
                default:
                    message += 'Une erreur inconnue est survenue.';
                    break;
            }
            setErrorMessage(message); // Set error message
        }
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-center my-2">
                                <p className="fw-bold fs-3">Inscription</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email"
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">Mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            placeholder="Mot de passe"
                                            autoComplete="new-password"
                                            required
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="form-label">Confirmer le mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            id="confirmPassword"
                                            placeholder="Confirmer le mot de passe"
                                            autoComplete="new-password"
                                            required
                                            value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                        </button>
                                    </div>
                                </div>
                                {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                                <div className="d-flex justify-content-center my-5">
                                    <button type="submit" className="btn btn-warning">S'inscrire</button>
                                </div>
                                <p className="text-center">Vous avez déjà un compte ?
                                    <a href="/client/login" className="link"> Se connecter</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
