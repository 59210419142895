import React from 'react';
import {Link} from "react-router-dom";

const ProductsComponent = () => {
    const productsHabitat = [
        {
            id: 1,
            name: "Isolation extérieure",
            image: "/images/landing/shop/isolation_exterieur.jpeg",
            description: 'Description of Photovoltaïques',
        },
        {
            id: 2,
            name: "Isolation intérieure",
            image: "/images/landing/shop/isolation.jpeg",
            description: 'Description of Pompes à chaleur',
        },
        {
            id: 3,
            name: "Isolation des combles",
            image: "/images/landing/shop/isolation_combles.jpeg",
            description: 'Description of Isolation extérieur',
        },
        {
            id: 4,
            name: "Fenêtres",
            image: '/images/landing/shop/fenetres.jpeg',
            description: 'Description of Isolation extérieur',
        },
        {
            id: 5,
            name: "Salle de bain PMR",
            image: "/images/landing/shop/pmr.jpeg",
            description: 'Description of Fenêtres',
        },
        {
            id: 6,
            name: "Vmc double flux",
            image: "/images/landing/shop/ventilations.jpeg",
            description: 'Description of Chauffe eau thermodynamique',
        },
    ];

    const productsEnergie = [
        {
            id: 1,
            name: 'Pompes à chaleur',
            image: '/images/landing/shop/pompe.jpeg',
            description: 'Description of Pompes à chaleur',
        },
        {
            id: 2,
            name: 'Photovoltaïques',
            image: '/images/landing/shop/photovoltaique.jpeg',
            description: 'Description of Photovoltaïques',
        },
        {
            id: 3,
            name: 'Panneaux solaires',
            image: '/images/landing/shop/photovoltaiques.jpg',
            description: 'Description of Isolation extérieur',
        },
        {
            id: 4,
            name:"Climatisation",
            image: "/images/landing/shop/climatisation.jpeg",
            description: 'Description of Fenêtres',
        },
        {
            id: 5,
            name: "Ballon thermodynamique",
            image: "/images/landing/shop/ballon.jpeg",
            description: 'Description of Chauffe eau thermodynamique',
        },
    ];

    return (
        <div className="container-fluid my-5" style={{backgroundColor: '#021288'}}>
            <div className="container py-5">
                <h1 className="text-center text-warning mb-4">Commander en ligne</h1>
                <h3 className="text-center text-warning mb-4 text-white">Pôle habitat</h3>
                <div className="row justify-content-center">
                    {productsHabitat.map(product => (
                        <div className="col-md-2 my-2" key={`habitat-${product.id}`}>
                            <div className="text-center">
                                <img src={product.image} alt={product.name} className="img-fluid mb-3" style={{maxHeight: '150px'}} />
                                <p className="text-white mb-3">{product.name}</p>
                                <Link to="/shop"><button className="btn btn-warning myBtn">Acheter</button></Link>
                            </div>
                        </div>
                    ))}
                </div>
                <h3 className="text-center text-warning mb-4 text-white mt-4">Pôle Energie</h3>
                <div className="row justify-content-center">
                    {productsEnergie.map(product => (
                        <div className="col-md-2 my-2" key={`energie-${product.id}`}>
                            <div className="text-center">
                                <img src={product.image} alt={product.name} className="img-fluid mb-3" style={{maxHeight: '150px'}} />
                                <p className="text-white mb-3">{product.name}</p>
                                <Link to="/shop"><button className="btn btn-warning myBtn">Acheter</button></Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProductsComponent;
