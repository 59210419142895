import React from 'react';
import {Form} from 'react-bootstrap';

const EstimationForm = () => {
    return (
        <Form.Group controlId="estimation" className="card my-4 p-4 shadow-sm">
            <div className="text-center mt-4">
                <p>Merci</p>
                <p>Un eco conseiller prendra contact avec vous dans les 48h</p>
            </div>
        </Form.Group>
    );
};

export default EstimationForm;
