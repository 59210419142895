import React from 'react';
import { Link } from "react-router-dom";

const HeroBanner = () => {
    return (
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-column justify-content-between align-items-center text-center position-relative myBanner" style={{
                        backgroundImage: 'url(/images/landing/banner.jpeg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '70vh',
                        color: '#fff'
                    }}>
                        <div className="position-absolute top-0 end-0 mt-2 me-3">
                            <div className="badge text-bg-primary p-3 rounded-pill pastille">
                                Votre expert énergétique depuis 12 ans
                            </div>
                        </div>
                        <div className="mt-5 pt-5 mt-md-3 pt-md-3 w-100 px-3 px-lg-5">
                            <h1 className="display-5 mb-5 fw-semibold">
                                L'Agence Locale de l'Énergie et de l'Habitat pour améliorer
                                et rendre les habitations écoénergétiques
                            </h1>
                        </div>
                        <div className="mb-5 w-100">
                            <Link to="/contact">
                                <button className="btn btn-lg myBtn bannerBtn">
                                    Contactez-nous dès maintenant
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroBanner;
