import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from "react-scroll";

const ActivitiesComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const scrollToHash = () => {
            const hash = location.hash;
            if (hash && hash.startsWith('#apropos')) {
                const id = hash.replace('#', '');
                setTimeout(() => {
                    scroller.scrollTo(id, {
                        duration: 500,
                        delay: 0,
                        smooth: 'easeInOutQuart',
                        offset: -90 // Adjust offset for fixed header, etc.
                    });
                }, 100); // Adjust delay to ensure content is rendered
            }
        };

        // Call scrollToHash once to handle the initial load
        scrollToHash();

        // Listen for hash changes
        window.addEventListener('hashchange', scrollToHash);

        return () => {
            window.removeEventListener('hashchange', scrollToHash);
        };
    }, [location]);

    // Data for each activity
    const habitat = [
        {
            title: "Isolation extérieure",
            image: '/images/landing/shop/exterieur.jpeg',
            description: "Service d'isolation extérieure des bâtiments : une solution efficace pour réduire les pertes de chaleur, améliorer l'efficacité énergétique et diminuer les coûts de chauffage."
        },
        {
            title: "Isolation intérieure",
            image: "/images/landing/activites/isolation_interieur.jpeg",
            description: "Service d'isolation intérieure des bâtiments : une solution efficace pour réduire les pertes de chaleur, améliorer l'efficacité énergétique et diminuer les coûts de chauffage."
        },
        {
            title: "Isolation des combles",
            image: "/images/landing/activites/isolation_combles.jpeg",
            description: "Service d'isolation des combles : une solution efficace pour réduire les pertes de chaleur, améliorer l'efficacité énergétique et diminuer les coûts de chauffage."
        },
        {
            title: "Menuiserie",
            image: '/images/landing/shop/fenetres.jpeg',
            description: "Service de pose de menuiseries intérieures et extérieures : une solution pour améliorer l'isolation thermique et acoustique des bâtiments, réduire les pertes de chaleur et augmenter le confort intérieur."
        },
        {
            title: "Salle de bain PMR",
            image: "/images/landing/activites/pmr.jpeg",
            description: "Service de rénovation de salle de bain pour les personnes à mobilité réduite, offrant des solutions adaptées pour améliorer l'accessibilité et la sécurité des espaces de bain."
        }
    ];

    const energie = [
        {
            title: "Pompe à chaleur",
            image: "/images/landing/activites/pompe.jpeg",
            description: "Service d'installation de systèmes de pompes à chaleur efficaces : une alternative énergétique pour le chauffage et la climatisation, réduisant la dépendance aux énergies fossiles et optimisant la consommation d'énergie."
        },
        {
            title: "Photovoltaïque",
            image: "/images/landing/activites/photovoltaiques.jpeg",
            description: "Solutions solaires personnalisées pour les particuliers et les entreprises, offrant des installations photovoltaïques pour une production d'énergie propre et renouvelable."
        },
        {
            title: "Panneau solaire",
            image: "/images/landing/activites/panneaux.jpg",
            description: "Service d'installation de panneaux solaires pour une production d'énergie propre et renouvelable : une solution écologique pour réduire l'empreinte carbone et les coûts énergétiques."
        },
        {
            title: "Climatisation",
            image: "/images/landing/activites/climatisation.jpeg",
            description: "Service d'installation de systèmes de climatisation efficaces : une solution pour le confort thermique des bâtiments, réduisant les températures élevées en été et les températures basses en hiver."
        },
        {
            title: "Ballon thermodynamique",
            image: "/images/landing/activites/ballon.jpeg",
            description: "Service d'installation de ballons thermodynamiques pour la production d'eau chaude sanitaire : une solution économe en énergie et respectueuse de l'environnement, réduisant les coûts de chauffage et les émissions de CO2."
        },
        {
            title: "VMC double flux",
            image: "/images/landing/activites/ventilation.jpg",
            description: "Service d'installation de systèmes de ventilation efficaces : une solution pour améliorer la qualité de l'air intérieur, réduire l'humidité et prévenir les problèmes de santé liés à la pollution de l'air."
        }
    ];

    const handleLearnMore = (activityTitle) => {
        // Navigate to the contact page and pass the activity title as a URL parameter
        navigate('/contact', { state: { activityTitle } });
    };

    return (
        <div className="container mt-4" id="apropos">
            <h1 className="text-center mb-4">Nos domaines d'activités</h1>
            <h3 className="text-center mt-3 mb-4">Pôle habitat</h3>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                {/* Map over the habitat activities array to render each activity card */}
                {habitat.map((activity, index) => (
                    <div key={index} className="col mb-4">
                        <Card className="h-100 d-flex flex-column">
                            <Card.Img variant="top" src={activity.image} alt={`Card ${index + 1}`} />
                            <Card.Body className="flex-grow-1 d-flex flex-column">
                                <Card.Title>{activity.title}</Card.Title>
                                <Card.Text>{activity.description}</Card.Text>
                                <div className="d-flex justify-content-center mt-auto">
                                    <Button variant="warning" className="myBtn" onClick={() => handleLearnMore(activity.title)}>En savoir plus</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
            <h3 className="text-center mt-3 mb-4">Pôle énergie</h3>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                {/* Map over the energie activities array to render each activity card */}
                {energie.map((activity, index) => (
                    <div key={index} className="col mb-4">
                        <Card className="h-100 d-flex flex-column">
                            <Card.Img variant="top" src={activity.image} alt={`Card ${index + 1}`} />
                            <Card.Body className="flex-grow-1 d-flex flex-column">
                                <Card.Title>{activity.title}</Card.Title>
                                <Card.Text>{activity.description}</Card.Text>
                                <div className="d-flex justify-content-center mt-auto">
                                    <Button variant="warning" className="myBtn" onClick={() => handleLearnMore(activity.title)}>En savoir plus</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ActivitiesComponent;