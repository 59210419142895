import React, { useEffect, useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import { CartContext } from '../components/CartContext';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Shop = () => {
    const { cart, addToCart } = useContext(CartContext);

    const productsHabitat = [
        {
            id: 1,
            name: "Isolation extérieure",
            image: '/images/landing/shop/exterieur.jpeg',
            prix: 'À partir de 44€/m²'
        },
        {
            id: 2,
            name: "Isolation intérieure",
            image: '/images/landing/shop/interieur.jpeg',
            prix: ''
        },
        {
            id: 3,
            name: "Isolation des combles",
            image: '/images/landing/shop/comble.jpeg',
            prix: 'À partir de 13€/m²'
        },
        {
            id: 4,
            name: "Fenêtres",
            image: '/images/landing/shop/fenetre.jpeg',
            prix: ''
        },
        {
            id: 5,
            name: "Salle de bain PMR",
            image: '/images/landing/shop/salle.jpeg',
            prix: ''
        },
        {
            id: 6,
            name: "Vmc double flux",
            image: '/images/landing/shop/ventilation.jpeg',
            prix: 'À partir de 890€'
        },
    ];

    const productsEnergie = [
        {
            id: 7,
            name: 'Pompes à chaleur',
            image: '/images/landing/shop/pompe.jpeg',
            prix: 'À partir de 2 000€'
        },
        {
            id: 8,
            name: 'Photovoltaïques',
            image: '/images/landing/shop/photovoltaiques.jpeg',
            prix: 'À partir de 3 990€'
        },
        {
            id: 9,
            name: 'Panneaux solaires',
            image: '/images/landing/shop/panneaux.jpg',
            prix: 'À partir de 990€'
        },
        {
            id: 10,
            name:"Climatisation",
            image: '/images/landing/shop/climatisation.jpeg',
            prix: 'À partir de 2 990€'
        },
        {
            id: 11,
            name: "Ballon thermodynamique",
            image: '/images/landing/shop/ballons.jpeg',
            prix: 'À partir de 2 200€'
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    }, []);

    return (
        <div className="container my-5">
            <h1 className="text-center mt-3 mb-4">Boutique en ligne</h1>
            {cart.length > 0 && (
                <Link to="/cart" className="cart-button">
                    <i className="bi bi-cart3"></i>
                    <span className="badge">{cart.length}</span>
                </Link>
            )}

            <h4 className="text-center mt-3 mb-4">Pôle habitat</h4>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {productsHabitat.map(product => (
                    <div key={product.id} className="col">
                        <Card className="h-100">
                            <Card.Img variant="top" src={product.image} alt={product.name} />
                            <Card.Body>
                                <Card.Title>{product.name}</Card.Title>
                                <Card.Text>{product.description}</Card.Text>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Button
                                        className="btn btn-warning"
                                        onClick={() => addToCart(product)}
                                    >
                                        Ajouter au panier
                                    </Button>
                                    <span className="text-muted">{product.prix}</span>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
            <h4 className="text-center mt-5 mb-4">Pôle énergie</h4>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {productsEnergie.map(product => (
                    <div key={product.id} className="col">
                        <Card className="h-100">
                            <Card.Img variant="top" src={product.image} alt={product.name} />
                            <Card.Body>
                                <Card.Title>{product.name}</Card.Title>
                                <Card.Text>{product.description}</Card.Text>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Button
                                        className="btn btn-warning"
                                        onClick={() => addToCart(product)}
                                    >
                                        Ajouter au panier
                                    </Button>
                                    <span className="text-muted">{product.prix}</span>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Shop;
