import React from 'react';

const ContactForm = ({ formData, formErrors, handleInputChange }) => {
    return (
        <div className="card my-4 p-4 shadow-sm">
            <label htmlFor="field10" className="form-label ask mb-3">Vos données de contact</label>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Nom"
                        aria-label="Nom"
                        name="lastname"
                        id="lastname"
                        value={formData.lastname}
                        onChange={handleInputChange}
                    />
                    <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.lastname}</div>
                </div>
                <div className="col-md-6 mb-2">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Prénom"
                        aria-label="Prénom"
                        name="firstname"
                        id="firstname"
                        value={formData.firstname}
                        onChange={handleInputChange}
                    />
                    <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.firstname}</div>
                </div>
                <div className="col-md-6 mb-2">
                    <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        aria-label="Email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.email}</div>
                </div>
                <div className="col-md-6 mb-2">
                    <input
                        className="form-control"
                        type="tel"
                        placeholder="Téléphone"
                        aria-label="Téléphone"
                        name="phone"
                        id="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                    />
                    <div className="error-message" style={{ color: 'red', fontStyle: 'italic' }}>{formErrors.phone}</div>
                </div>
                <div className="col-12 my-2">
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="rgpd"
                            name="rgpd"
                            checked={formData.rgpd}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="rgpd">
                            J'autorise le traitement de mes données à des fins de conseil et d'information sur les systèmes d'énergie solaire.
                        </label>
                        {!formData.rgpd && <div className="error-message" style={{ color: 'red', fontStyle: 'italic', marginTop: '5px' }}>Vous devez accepter nos politiques des données</div>}
                    </div>
                </div>
                <div className="col-12 mt-3 fw-bold">
                    En nous soumettant vos informations, vous obtiendrez immédiatement des estimations préliminaires vous permettant d'estimer le potentiel de votre toiture et d'économies réalisables.
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
