import React from 'react';
import { useParams } from 'react-router-dom';

const ProductDetail = () => {
    const { productId } = useParams();

    // Données statiques des produits
    const productData = {
        1: {
            name: 'Isolation extérieur',
            image: '/images/landing/produit5.jpeg',
            description: "Les panneaux solaires photovoltaïques transforment la lumière du soleil en électricité grâce à la technologie photovoltaïque. Ils sont composés de cellules photovoltaïques qui absorbent les photons de la lumière solaire et libèrent des électrons, créant ainsi un courant électrique. Cette électricité peut être utilisée directement dans les habitations ou stockée dans des batteries pour une utilisation ultérieure. Les panneaux solaires photovoltaïques sont une source d'énergie renouvelable et respectueuse de l'environnement, car ils ne produisent pas de pollution lors de leur fonctionnement.",
            price: 1500,
            specifications: {
                dimensions: '1000mm x 500mm x 50mm',
                poids: '10 kg',
                garantie: '5 ans'
            },
            reviews: [
                {
                    id: 1,
                    user: 'Jean Dupont',
                    rating: 4,
                    comment: 'Excellent produit, fonctionne comme prévu.'
                },
                {
                    id: 2,
                    user: 'Marie Martin',
                    rating: 5,
                    comment: 'Excellente qualité et livraison rapide.'
                }
            ]
        }
    };

    // Obtenir les détails du produit en fonction de l'ID du produit
    const product = productData[productId];

    if (!product) {
        return <div>Produit non trouvé</div>;
    }

    return (
        <div className="container my-5">
            <h1 className="text-center mt-3 mb-4">{product.name}</h1>
            <div className="row">
                <div className="col-md-6">
                    <img src={product.image} className="img-fluid" alt={product.name} />
                </div>
                <div className="col-md-6">
                    <h3>Description</h3>
                    <p>{product.description}</p>
                    <h3>Spécifications</h3>
                    <ul>
                        {Object.entries(product.specifications).map(([key, value]) => (
                            <li key={key}><strong>{key}:</strong> {value}</li>
                        ))}
                    </ul>
                    <h3>Prix: €{product.price}</h3>
                    <button className="btn btn-warning">Ajouter au panier</button>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col">
                    <h3>Avis</h3>
                    {product.reviews.map(review => (
                        <div key={review.id}>
                            <h5>{review.user}</h5>
                            <p>Évaluation: {review.rating}</p>
                            <p>{review.comment}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;
