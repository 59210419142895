import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Alert, Modal, Button } from "react-bootstrap";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [resetEmail, setResetEmail] = useState(''); // State for reset email input
    const [resetMessage, setResetMessage] = useState(''); // State for reset email response message
    const navigate = useNavigate();
    const auth = getAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear any previous error message

        try {
            await signInWithEmailAndPassword(auth, email, password);
            // Handle successful login, e.g., navigate to a different page
            navigate('/profil'); // Redirect to a different page upon successful login
        } catch (error) {
            let message = 'Erreur de connexion : ';
            switch (error.code) {
                case 'auth/invalid-email':
                    message += 'Email invalide.';
                    break;
                case 'auth/user-disabled':
                    message += 'Utilisateur désactivé.';
                    break;
                case 'auth/user-not-found':
                    message += 'Utilisateur non trouvé.';
                    break;
                case 'auth/wrong-password':
                    message += 'Mot de passe incorrect.';
                    break;
                default:
                    message += 'Une erreur est survenue.';
                    break;
            }
            setErrorMessage(message); // Set error message
        }
    };

    const handleResetPassword = async () => {
        setResetMessage(''); // Clear any previous reset message
        setErrorMessage(''); // Clear any previous error message

        try {
            await sendPasswordResetEmail(auth, resetEmail);
            setResetMessage('Un email de réinitialisation a été envoyé.');
        } catch (error) {
            let message = 'Erreur lors de l\'envoi de l\'email de réinitialisation : ';
            switch (error.code) {
                case 'auth/invalid-email':
                    message += 'Email invalide.';
                    break;
                case 'auth/user-not-found':
                    message += 'Utilisateur non trouvé.';
                    break;
                case 'auth/invalid-credential':
                    message += 'Email ou Mot de passe erroné';
                    break;
                default:
                    message += 'Une erreur inconnue est survenue.';
                    break;
            }
            setResetMessage(message); // Set error message
        }
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-center my-2">
                                <p className="fw-bold fs-3">Se connecter</p>
                            </div>
                            <form onSubmit={handleSubmit} >
                                <div className="mb-4">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email"
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">Mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            placeholder="Mot de passe"
                                            autoComplete="current-password"
                                            required
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                        </button>
                                    </div>
                                </div>
                                {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                                <div className="d-flex justify-content-center my-5">
                                    <button type="submit" className="btn btn-warning">Se connecter</button>
                                </div>
                                <p className="text-center">Pas encore de compte ?
                                    <a href="/signup" className="link"> S'inscrire</a>
                                </p>
                                <p className="text-center">Mot de passe oublié?
                                    <a href="#" className="link" onClick={() => setShowModal(true)}> Réinitialiser mon mot de passe</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for password reset */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Réinitialiser le mot de passe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <label htmlFor="resetEmail" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="resetEmail"
                            placeholder="Email"
                            required
                            value={resetEmail}
                            onChange={e => setResetEmail(e.target.value)}
                        />
                    </div>
                    {resetMessage && <Alert variant="info">{resetMessage}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Fermer</Button>
                    <Button variant="primary" onClick={handleResetPassword}>Envoyer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Login;
