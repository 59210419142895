import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from './CartContext';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CartComponent = () => {
    const { cart, removeFromCart, clearCart } = useContext(CartContext);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    });

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (cart.length === 0) {
            navigate('/shop');
        }
    }, [cart, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const dataToSend = {
            ...formData,
            cart
        };
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mail/shop`, dataToSend);
            setModalMessage('On a reçu vos informations avec succès, un eco conseiller prendra contact avec vous dans les 48h.');
            setShowModal(true);
            setFormData({
                firstName: '',
                lastName: '',
                phone: '',
                email: ''
            });
            setLoading(false);
        } catch (error) {
            console.error('An error occurred while sending the email:', error);
            setModalMessage("Une erreur s'est produite lors de l'enregistrement. Veuillez réessayer plus tard.");
            setShowModal(true);
            setLoading(false);
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
        clearCart();
        navigate('/shop');
    };

    const isFormValid = () => {
        return (
            formData.firstName.trim() !== '' &&
            formData.lastName.trim() !== '' &&
            formData.phone.trim() !== '' &&
            formData.email.trim() !== ''
        );
    };

    return (
        <div className="container my-5">
            <h1 className="text-center mt-3 mb-4">Mon Panier</h1>
            <div className="row">
                <div className="col-lg-6 mt-2 d-flex justify-content-center">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 g-4 justify-content-center">
                        {cart.map((product, index) => (
                            <div key={index} className="col d-flex justify-content-center">
                                <Card className="h-100" style={{ maxWidth: '18rem' }}>
                                    <Card.Img variant="top" src={product.image} alt={product.name} />
                                    <Card.Body>
                                        <Card.Title>{product.name}</Card.Title>
                                        <Card.Text>{product.description}</Card.Text>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Button
                                                className="btn btn-danger"
                                                onClick={() => removeFromCart(product.id)}
                                            >
                                                Supprimer
                                            </Button>
                                            <span className="text-muted">{product.prix}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-lg-6 mt-2">
                    <h2>Vos informations</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">Prénom</label>
                            <input type="text" className="form-control" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Votre prénom" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastName" className="form-label">Nom</label>
                            <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Votre nom" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Votre email" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Téléphone</label>
                            <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} placeholder="Votre téléphone" required />
                        </div>
                        <Button type="submit" className="btn btn-warning" disabled={!isFormValid() || loading}>{loading ? 'Chargement...' : 'Valider'}</Button>
                    </form>
                </div>
            </div>

            {/* Bootstrap Modal */}
            <div className={`modal fade ${showModal ? 'show d-block' : ''}`} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Merci</h5>
                        </div>
                        <div className="modal-body">
                            <p>{modalMessage}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" data-dismiss="modal" onClick={handleModalClose}>Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Bootstrap Modal */}
        </div>
    );
};

export default CartComponent;
