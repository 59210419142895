import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PartnersCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="container my-5">
            <h1 className="text-center mt-3 mb-4">Nos partenaires</h1>
            <Slider {...settings}>
                <div>
                    <img src="/images/landing/partners/DAIKIN.png" className="partner-image img-fluid" alt="Daikin"/>
                </div>
                <div>
                    <img src="/images/landing/partners/panasonic.png" className="partner-image img-fluid" alt="Panasonic"/>
                </div>
                <div>
                    <img src="/images/landing/partners/Sunpower.png" className="partner-image img-fluid pe-2" alt="Sunpower"/>
                </div>
            </Slider>
        </div>
    );
}

export default PartnersCarousel;
